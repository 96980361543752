import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

const burger = () => {
	const burgerEl      = document?.querySelector('[data-burger]');
	const menu          = document?.querySelector('[data-menu]');
	const headerWrap    = document?.querySelector('[data-wrap]');
	const targetElement = document.querySelector('body');
	const overlay       = document?.querySelector('[data-overlay]');
	const page          = document.querySelector('.page-wrapper');

	function disableScroll() {
		const pagePosition = window.scrollY;
		targetElement.classList.add('scroll');
		document.body.dataset.position = pagePosition;
	}

	function enableScroll() {
		targetElement.classList.remove('scroll');
		document.body.removeAttribute('data-position');
	}

	burgerEl?.addEventListener('click', () => {
		burgerEl?.classList.toggle('burger--active');
		menu?.classList.toggle('active');
		headerWrap.classList.toggle('active');
		overlay?.classList.toggle('active');

		if (menu.classList.contains('active')) {
			burgerEl?.setAttribute('aria-expanded', 'true');
			burgerEl?.setAttribute('aria-label', 'Сlose menu');
			disableScroll();
		} else {
			burgerEl?.setAttribute('aria-expanded', 'false');
			burgerEl?.setAttribute('aria-label', 'Open menu');
			enableScroll();
		}
	});

	function isDescendant(parent, child) {
		let node = child.parentNode;
		while (node != null) {
			if (node == parent) {
				return true;
			}
			node = node.parentNode;
		}
		return false;
	}

	function removeActiveClasses() {
		burgerEl?.classList.remove('burger--active');
		menu?.classList.remove('active');
		headerWrap.classList.remove('active');
		overlay?.classList.remove('active');
	}

	document.addEventListener('click', (event) => {
		if (!isDescendant(headerWrap, event.target)) {
			removeActiveClasses();
		}
	});

	overlay.addEventListener('click', (e) => {
		if (e.currentTarget === e.target) {
			menu?.classList.remove('active');
			overlay?.classList.remove('active');
			burgerEl?.classList.remove('burger--active');
			headerWrap.classList.remove('active');
			enableScroll();
		}
	});
};

export default burger;
