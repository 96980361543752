import DropPanel  from './components/DropPanel';
import MainMenu   from './components/MainMenu';
import burger     from './components/Burger';
import popupsFunc from './components/modal';

// Init
function init() {
	new DropPanel();
	burger();
	popupsFunc.init();
}

(function () {
	init();
}());
